body,
html {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
main {
  display: flex;
  justify-content: center;
  /* position: relative; */
}
a {
  cursor: pointer;
}

.donationApp__container {
  /* display: flex;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: space-between; */
}
.donationApp__banner {
  width: 100%;
  height: 33vh;
  position: relative;
  background: url("./assets/header_background.png");
  background-size: 102% 100%;
  background-repeat: no-repeat;
}

.donationApp__banner__logo {
  width: 8vw;
  margin: auto;
  height: 8vw;
  position: relative;
  background: url("./assets/cicrle_logo.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  top: 7vh;
}
.donationApp__banner__subheader {
  position: absolute;
  width: 100%;
  height: 60px;
  top: 25vh;
  font-family: "Fira Sans", sans-serif;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  text-transform: initial;
  display: block;
  font-size: 2rem;
}
.donationApp__content {
  max-width: 25vw;
  border-radius: 6px;
  box-shadow: 0 1.3px 1px rgba(0, 0, 0, 0.001),
    0 2.8px 2.2px rgba(0, 0, 0, 0.001), 0 4.8px 3.8px rgba(0, 0, 0, 0.001),
    0 7.4px 5.8px rgba(0, 0, 0, 0), 0 10.9px 8.7px rgba(0, 0, 0, 0),
    0 16.1px 12.7px rgba(0, 0, 0, 0), 0 24.1px 19.1px rgba(0, 0, 0, 0.005),
    0 38.4px 30.4px rgba(0, 0, 0, 0.025), 0 72px 57px rgba(0, 0, 0, 0.08);
  background-color: rgba(255, 255, 255, 0.9);
  padding: 15px 30px;
}
.donationApp__input {
  padding: 20px;
  border-radius: 8px;
  border: solid 1px #dcdcdc;
  width: 100%;
  box-sizing: border-box;
  font-size: 1.2rem;
  transition: all 0.3s ease-in-out;
  outline: none;
}

.donationApp__input.disabled-input {
  opacity: 0.2;
  filter: grayscale(70%) blur(2px);
}
.donationApp__simple-button {
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
}

.input-error {
  box-shadow: 0 0 5px rgb(197, 33, 68);
  border: 1px solid rgb(197, 33, 68);
}

.donationApp__input::placeholder {
  font-family: "Noto Sans", sans-serif;
  font-size: 1.2rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: left;
  color: #1c3263;
}

.donationApp__backButton {
  position: absolute;
  left: 20px;
  top: 20px;
  display: flex;
  align-items: center;
  padding: 5px 10px;
  border: 1px solid rgba(255, 255, 255, 0.6);
  border-radius: 6px;
  background-color: transparent;
  color: white;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.donationApp__backButton:hover {
  font-weight: bold;
  border: 1px solid #3b8d99;
  box-shadow: 0 0.8px 2.2px rgba(0, 0, 0, 0.02),
    0 2px 5.3px rgba(0, 0, 0, 0.028), 0 3.8px 10px rgba(0, 0, 0, 0.035),
    0 6.7px 17.9px rgba(0, 0, 0, 0.042), 0 12.5px 33.4px rgba(0, 0, 0, 0.05),
    0 30px 80px rgba(0, 0, 0, 0.07);
  background-color: #3b8d99;
  color: white;
  transition: all 0.3s ease-in-out;
}

.donationApp__backButton:hover > .donationApp__backButton__icon {
  background: url("./assets/back3.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 19px;
  height: 17px;
  transition: all 0.3s ease-in-out;
}

.donationApp__backButton__icon {
  background: url("./assets/back3.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 19px;
  height: 17px;
  margin-bottom: 2px;
}

.donationApp__backButton__text {
  margin-left: 5px;
  font-family: "Fira Sans", sans-serif;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: center;
  font-size: 16px;
}

.donationApp__submitBtn {
  background-color: #3b8d99;
  color: white;
  border-radius: 1px;
  font-family: "Noto Sans", sans-serif;
  font-size: 1.3rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  border: transparent;
  width: 100%;
  margin-top: 10px;
  padding: 20px 10px;
  cursor: pointer;
  transition: all 0.4s ease-in-out;
}

.donationApp__submitBtn:hover {
  transform: scale(1.05);
  box-shadow: 0 0.8px 2.2px rgba(0, 0, 0, 0.02),
    0 2px 5.3px rgba(0, 0, 0, 0.028), 0 3.8px 10px rgba(0, 0, 0, 0.035),
    0 6.7px 17.9px rgba(0, 0, 0, 0.042), 0 12.5px 33.4px rgba(0, 0, 0, 0.05),
    0 30px 80px rgba(0, 0, 0, 0.07);
}

.donationApp__form__textRow {
  display: inline-block;
  width: 100%;
  text-align: center;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  font-size: 1rem;
  text-align: center;
  color: #1c3263;
  font-family: "Noto Sans", sans-serif;
  margin: 10px 0;
}

.donationApp__input-container__checkbox {
  margin-top: 15px;
  text-align: initial;
  display: flex;
  transition: all 0.3s ease-in-out;
}

a {
  color: #6b6b83;
  text-decoration: none;
  font-weight: bold;
}
label {
  margin-left: 5px;
  font-family: "Noto Sans", sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: left;
  color: #1c3263;
}

.donationApp__checkbox-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.donationApp__checkbox-round {
  position: relative;
  width: 33%;
  text-align: left;
  margin: 5px 0;
  box-sizing: border-box;
}

.donationApp__checkbox-round label {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  height: 28px;
  left: 0;
  position: absolute;
  top: 0;
  width: 28px;
  box-sizing: border-box;
}

.donationApp__checkbox-round label:after {
  border: 2px solid #fff;
  border-radius: 50%;
  content: "";
  height: 15px;
  left: 4px;
  opacity: 0;
  position: absolute;
  top: 4px;
  transform: rotate(-45deg);
  width: 15px;
  background-color: #6b6b83;
}

.donationApp__checkbox-round input[type="checkbox"] {
  visibility: hidden;
}

.donationApp__checkbox-round input[type="checkbox"]:checked + label {
  background-color: white;
  border-color: #6b6b83;
}

.donationApp__checkbox-round input[type="checkbox"]:checked + label:after {
  opacity: 1;
}
.donationApp__checkbox-name {
  font-family: "Noto Sans", sans-serif;
  font-size: 21px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: #1c3263;
  margin-top: 1px;
  display: inline-block;
  margin-left: 20%;
  box-sizing: border-box;
}

.donationApp__input-container__email {
}

.donationApp__input-container__amount {
  justify-content: center;
  font-family: "Noto Sans", sans-serif;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: #1c3263;
  display: flex;
  margin: 10px 0;
  align-items: center;
}

.donationApp__checkbox {
  width: 25px;
  height: 25px;
  border-radius: 2px;
  border: solid 1px #dcdcdc;
  position: relative;
  top: -4px;
}

/* mobile settings */

.button {
  margin: 0 25px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid rgba(0, 0, 0, 0.2);
  background-color: transparent;
  padding: 10px;
  box-sizing: border-box;
  cursor: pointer;
  outline: none;
}
.button:focus {
  box-shadow: 0 0 1pt 1px #4baa8a;
}

.button-add {
  background: url("./assets/add.png");
  background-size: 40%;
  background-position: center;
  background-repeat: no-repeat;
  transition: all ease-in-out 0.2s;
  opacity: 0.8;
}
.button-add:hover {
  background: url("./assets/add _color.png");
  background-size: 40%;
  background-position: center;
  background-repeat: no-repeat;
  border: 1px solid #4baa8a;
}

.button-minus {
  background: url("./assets/minus.png");
  background-size: 40%;
  background-position: center;
  background-repeat: no-repeat;
  transition: all ease-in-out 0.2s;
  opacity: 0.8;
}

.button-minus:hover {
  background: url("./assets/minus _color.png");
  background-size: 40%;
  background-position: center;
  background-repeat: no-repeat;
  border: 1px solid #4baa8a;
}

@media (max-width: 600px) {
  .donationApp__banner {
    background-size: 111% 100%;
    height: 17vh;
    width: 100%;
    overflow: hidden;
  }

  .donationApp__content {
    max-width: 100%;
    padding: 10px 15px;
    box-shadow: none;
  }

  .donationApp__banner__name {
    font-size: 3.5rem;
    top: 3vh;
  }
  .donationApp__banner__subheader {
    font-size: 1.5rem;
  }
  .donationApp__banner__logo {
    width: 70%;
    height: 70%;
    top: 0;
    background: url("./assets/cicrle_logo.png");
    background-size: contain;
    background-repeat: no-repeat;
    left: 25%;
    top: 16%;
  }

  .donationApp__banner__subheader {
    top: 17vh;
  }
  .button {
    width: 50px;
    height: 50px;
  }
  .donationApp__input-container__amount {
    font-size: 28px;
  }
}
.donationApp__radio-container {
  display: flex;
  flex-direction: column;
  margin: 15px 0;
  font-family: "Noto Sans", sans-serif;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: #1c3263;
}

.donationApp__radio-row {
  display: flex;
  flex-direction: row;
  margin: 5px 0;
  cursor: pointer;
}
input {
  cursor: pointer;
}
label {
  cursor: pointer;
}

.donationApp__row {
  /* border: 1px solid rgba(0, 0, 0, 0.2); */
  border-radius: 8px;
  margin-left: 15px;

  padding: 15px 10px;
}
.checkbox-error {
  outline: 2px solid red;
  box-shadow: 0px 1px 2px red;
}
